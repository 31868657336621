// Add anything custom here -- mixins, variable overrides.
// by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier

$page-bg: #f8f8f8;

$neutralcolor-00: #ffffff;
$neutralcolor-10: #f6f7f8;
$neutralcolor-20: #e9ebef;
$neutralcolor-30: #e0e2e7;
$neutralcolor-40: #e0e2e7;
$neutralcolor-50: #d6d9df;
$neutralcolor-60: #c1c5d0;
$neutralcolor-70: #4c535d;
$neutralcolor-80: #2e3238;
$neutralcolor-90: #121212;
$neutralcolor-100: #000000;

$actioncolor-a: #121212;
$actioncolor-b: #35393f;
$actioncolor-c: #4c535d;
$actioncolor-d: #e9ebef;
$actioncolor-e: #ffffff;

:root {
  --neutralcolor-00: #{$neutralcolor-00};
  --neutralcolor-10: #{$neutralcolor-10};
  --neutralcolor-20: #{$neutralcolor-20};
  --neutralcolor-30: #{$neutralcolor-30};
  --neutralcolor-40: #{$neutralcolor-40};
  --neutralcolor-50: #{$neutralcolor-50};
  --neutralcolor-60: #{$neutralcolor-60};
  --neutralcolor-70: #{$neutralcolor-70};
  --neutralcolor-80: #{$neutralcolor-80};
  --neutralcolor-90: #{$neutralcolor-90};
  --neutralcolor-100: #{$neutralcolor-100};
  --actioncolor-a: #{$actioncolor-a};
  --actioncolor-b: #{$actioncolor-b};
  --actioncolor-c: #{$actioncolor-c};
  --actioncolor-d: #{$actioncolor-d};
  --actioncolor-e: #{$actioncolor-e};
}

body {
  min-height: 100%;
  height: auto;
}

.ant-select-dropdown {
  z-index: 3000;
}

.grecaptcha-badge {
  display: none !important;
}

.nav-mobile-slide-out .page-wrapper .page-content {
  background: $page-bg;
}

/* # Colors
========================================================================== */
.text-neutral {
  color: #ffffff;
  color: $neutralcolor-80 !important;
}
.text-dark,
.subheader-title,
.subheader-icon,
.subheader-title small {
  color: $neutralcolor-90 !important;
}
a.text-neutral:hover,
a.text-neutral:focus {
  color: #ffffff;
  color: $neutralcolor-70 !important;
}

/* # Buttons
========================================================================== */
.btn {
  box-shadow: none;
}

.btn-action {
  color: #ffffff;
  background-color: $actioncolor-a !important;
  border-color: $actioncolor-a !important;
}
.btn-action:hover,
.btn-action:focus {
  color: #ffffff;
  background-color: $actioncolor-b !important;
  border-color: $actioncolor-b !important;
}

.btn-action-b {
  color: #ffffff;
  background-color: $actioncolor-b !important;
  border-color: $actioncolor-b !important;
}
.btn-action-b:hover,
.btn-action-b:focus {
  color: #ffffff;
  background-color: $actioncolor-a !important;
  border-color: $actioncolor-a !important;
}

a,
button {
  &.bg-action {
    &:hover,
    &:focus {
      background-color: $actioncolor-b !important;
    }
  }
}

/* # Background
========================================================================== */
.bg-action {
  background-color: $actioncolor-a !important;
}

/* # Border
========================================================================== */
.border-action {
  border-color: $actioncolor-a !important;
}
.border-action-b {
  border-color: $actioncolor-b !important;
}
a,
button {
  &.border-action {
    &:hover,
    &:focus {
      border-color: $actioncolor-b !important;
    }
  }
}

/* # Header
========================================================================== */
#enterprise-menu .header-icon:not(.btn)[data-toggle="dropdown"][data-toggle="dropdown"]:after {
  display: none;
}

header.page-header {
  background-color: #e0e2e7;
  height: 85px;
}

.header-icon {
  color: #2e3238;
}

.header-icon:not(.btn) > [class*="fa-"]:first-child,
.header-icon:not(.btn) > .ni:first-child {
  color: #2e3238;
}

a.header-icon {
  background-color: transparent;
  border-radius: 8px;
  transition: all 0.1s linear;
}
.show a.header-icon {
  background-color: #f6f7f8;
}
.header-icon:not(.btn)[data-toggle="dropdown"] {
  + #profile-menu {
    top: 85px !important;
  }
  &:after {
    display: none;
  }
}
.header-btn[data-class="mobile-nav-on"] {
  background-image: none;
  background-color: transparent;
  border-color: transparent;
  color: #000;
}
.header-icon:not(.btn)[data-toggle="dropdown"].notification-dropdown ~ .dropdown-menu {
  top: 85px !important;
}
@media (min-width: 768px) {
  .header-icon:not(.btn)[data-toggle="dropdown"].notification-dropdown ~ .dropdown-menu {
    right: inherit !important;
  }
}
