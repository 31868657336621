/* #THEME COLOR (variable overrides)
========================================================================== */
$color-primary: #121212;
$color-secondary: #35393f;
$color-success: #419487;
$color-info: #428cf2;
$color-warning: #eca064;
$color-danger: #de545e;
$color-dark: #121212;
$color-light: #ffffff;

$color-fusion: darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%);

$brandcolor-primary-01: #b7b6b6;
$brandcolor-primary-02: #353635;

$neutralcolor-00: #ffffff;
$neutralcolor-10: #f6f7f8;
$neutralcolor-20: #e9ebef;
$neutralcolor-30: #e0e2e7;
$neutralcolor-40: #e0e2e7;
$neutralcolor-50: #d6d9df;
$neutralcolor-60: #c1c5d0;
$neutralcolor-70: #4c535d;
$neutralcolor-80: #2e3238;
$neutralcolor-90: #121212;
$neutralcolor-100: #000000;

$actioncolor-a: #121212;
$actioncolor-b: #35393f;
$actioncolor-c: #4c535d;
$actioncolor-d: #e9ebef;
$actioncolor-e: #ffffff;

:root {
  --neutralcolor-00: #{$neutralcolor-00};
  --neutralcolor-10: #{$neutralcolor-10};
  --neutralcolor-20: #{$neutralcolor-20};
  --neutralcolor-30: #{$neutralcolor-30};
  --neutralcolor-40: #{$neutralcolor-40};
  --neutralcolor-50: #{$neutralcolor-50};
  --neutralcolor-60: #{$neutralcolor-60};
  --neutralcolor-70: #{$neutralcolor-70};
  --neutralcolor-80: #{$neutralcolor-80};
  --neutralcolor-90: #{$neutralcolor-90};
  --neutralcolor-100: #{$neutralcolor-100};
  --actioncolor-a: #{$actioncolor-a};
  --actioncolor-b: #{$actioncolor-b};
  --actioncolor-c: #{$actioncolor-c};
  --actioncolor-d: #{$actioncolor-d};
  --actioncolor-e: #{$actioncolor-e};
}

$white: #ffffff !default;
$black: #121212 !default;

/* #GLOBAL IMPORTS
========================================================================== */
@import "./src/scss/_imports/_theme-modules-import";
// @import "./src/custom/webfonts/moderat-family/scss/_core";

$font-main: "Moderat";

body,
a,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-main !important;
  font: 400 1rem "Moderat";
  font-variant: none !important;
  font-feature-settings: normal !important;
}

.page-content-wrapper {
  background: #fff;
}

.bg-brand-gradient,
.nav-footer,
.page-logo,
.page-sidebar {
  background-image: none;
  background-color: $actioncolor-b;
  box-shadow: none;
}

.page-logo {
  height: 85px;
}

.page-inner .page-inner .bg-brand-gradient {
  background-color: $color-primary;
}

.nav-menu li {
  padding: 0rem 0.5rem 0.1rem 0.5rem;
  > a {
    min-height: 50px;
    border-radius: 4px;
    color: #ffffff;
    transition: all 0.3s ease-out;

    & > [class*="fa-"] {
      color: #ffffff !important;
    }

    &:hover {
      color: #ffffff;
      background-color: transparent;

      & > [class*="fa-"] {
        color: #ffffff !important;
      }
    }
  }

  &.nav-title {
    padding: 0.8125rem 2rem !important;
    color: #ffffff;
  }

  &.active {
    > a {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.active > a,
  > a:hover {
    background-color: #63a0e4;
  }
}

.mod-nav-link:not(.nav-function-top):not(.nav-function-minify):not(.mod-hide-nav-icons)
  ul.nav-menu:not(.nav-menu-compact)
  > li
  > ul:before {
  top: 50px;
}

.page-content-wrapper {
  background: #f6f7f8;
}

/* # CARD
========================================================================== */
.card {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: none;
  border-color: transparent;
  .card-body,
  .card-header,
  .card-footer {
    padding: 3rem 3rem;
  }
}

/* # Modal
========================================================================== */
.modal-header,
.modal-footer {
  padding: 3.45rem;
}
.modal-body {
  padding: 0 3.45rem;
}
.close {
  opacity: 1;
}

/* # Sidebar Menu
========================================================================== */

aside.page-sidebar {
  background-color: var(--theme-primary);
}

.nav-menu li a > .nav-link-text {
  font-size: 13px;
  font-weight: 300;
}

/* # Ant Design Override
========================================================================== */

.ant-select-dropdown {
  z-index: 3000 !important;
}

.ant-popover,
.ant-tooltip {
  z-index: 3000 !important;
}

.ant-select-item-option {
  overflow-wrap: break-word !important;
}

.ant-select-item-option-content {
  white-space: normal !important;
}

.ant-select-item-option:not(:last-child) {
  border-bottom: 1px solid #d8d8d8 !important;
}

.breadcrumb {
  padding-left: 0px !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

/* # Ant Design Modal
========================================================================== */

.ant-modal-wrap {
  z-index: 2000 !important;
}